import React from 'react';
import { graphql, Link } from 'gatsby';
import headerBG from '../images/slide03.jpg';
import sectionBG from '../images/section-bg01.png';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Layout from '../components/layout';

export const query = graphql`
  query ($slug: String!) {
    itemsJson(slug: { eq: $slug }) {
      id
      name
      description
      category
      image {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  }
`;

const ProductTemplate = ({ data }) => {
  // const image = getImage(data.itemsJson.image);
  const product = data.itemsJson;
  return (
    <Layout>
      <header className="page-header" data-background={headerBG} data-stellar-background-ratio="0.7">
        <div className="container">
          <h2>{product.name}</h2>
        </div>
        <div className="parallax-element" data-stellar-ratio="2"></div>
      </header>

      <section className="content-section" data-background={sectionBG} data-stellar-background-ratio="1.2">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="section-title text-left">
                <Link to="/products">
                  <h6>Back To All Products</h6>
                </Link>
                <h2>More About {product.name}</h2>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="side-image">
                <GatsbyImage image={data.itemsJson.image.childImageSharp.gatsbyImageData} alt={product.name} />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="side-content left">
                <p>{product.description}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ProductTemplate;
